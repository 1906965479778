.text-center {
    text-align: center;
}
.savecl {
    float: right;
}
.test{
    margin-right: 20px;
}
.textfieldstyle{
    color: gray !important;
}
.labelfieldstyle{
       color: black !important;
}