input[type=file] {
    width: 350px;
    max-width: 100%;
    color: #444;
    padding: 5px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #555;
    cursor: pointer;
  }
  
  input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #202D50;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
  }